.slider-nav {
  position: relative;
  display: inline-block;
  padding: 0 23px;

  .slick-dots {
    position: static;
    width: auto;
    display: flex !important;
    align-items: center;

    li,
    button,
    button::before {
      width: auto;
      height: auto;
    }

    li {
      margin: 0 10px;
    }

    button::before {
      content: '\f2f8';
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-size: 25px;
      font-weight: 700;
      position: relative;
      opacity: 1;
      color: #313131;
    }

    .slick-active button::before {
      content: '\f111';
      opacity: 1;
    }
  }

  .slick-arrow {
    height: auto;
    width: auto;

    &::before {
      font-family: 'Font Awesome 6 Pro', sans-serif;
      font-weight: 700;
      font-size: 26px;
      color: #313131;
      opacity: 1;
    }

    &.slick-prev {
      left: -15px;

      &::before {
        content: '\f053';
      }
    }

    &.slick-next {
      right: -15px;

      &::before {
        content: '\f054';
      }
    }
  }
}

body:not(.home) .testimonials-panel.panel {
  padding-top: 0;
}

.testimonials-panel {
  .h1 {
    margin: 0 auto 47px;
    padding-bottom: 8px;
    max-width: 360px;
  }
}

footer.footer-panel {
  background-color: $white;
  padding: 30px 0 5px;

  @include wide {
    padding: 30px 0 20px;
  }

  a {
    color: $body-color;
  }

  a[href^="tel:"] {
    color: $body-color !important;
  }

  .brand {
    margin-bottom: 35px;
  }

  .contact-info {
    margin-bottom: 20px;

    span {
      display: block;

      @include tablet {
        display: inline;
      }
    }

    span:not(:last-of-type) {
      @include tablet {
        &::after {
          content: '|';
          margin: 0 20px;
        }
      }
    }
  }

  ul.nav {
    li {
      margin: 0 25px 20px;
    }
  }
}

footer.copyright {
  background-color: #f5f5f5;
  padding: 10px 0 8px;

  *,
  a:hover {
    font-family: 'Ariel', sans-serif;
    font-size: 12px;
    color: $black;
  }

  a::after {
    display: none;
  }
}
