#skip-navigation {
  position: absolute;
  display: block;
  background: $white;
  color: $heading-color;
  padding: 15px;
  text-align: center;
  transform: translateY(-101%);
  transition: transform 0.25s ease-in-out;
  z-index: 10;

  &:focus {
    transform: translateY(0);
    transition: transform 0.25s ease-in-out;
  }
}

#modal {
  overflow: scroll;
  background-color: rgba($black, 0.5);

  .modal-dialog {
    @include tablet {
      max-width: 75%;
    }

    @include hd {
      max-width: 50%;
    }
  }
}

.brand {
  display: inline-block;
  max-width: 160px;

  @include desktop {
    max-width: 210px;
  }

  @include wide {
    max-width: 270px;
  }
}

.banner {
  background-color: $white;
  border-bottom: 7px solid;
  box-shadow: 0 3px 0 $white;
  position: relative;
  z-index: 2;

  .brand {
    padding: 8px 0;

    @include desktop {
      margin-right: 20px;
      flex-shrink: 0;
    }

    @include wide {
      margin-right: 40px;
    }
  }

  nav {
    @include desktop {
      width: 100%;
      margin-left: 145px;
    }
  }

  ul.nav {
    justify-content: flex-end;
  }

  a {
    font-size: 15px;
    font-family: $secondary-font;
    color: $body-color;

    @include wide {
      font-size: 16px;
    }

    @include hd {
      font-size: 17px;
    }
  }

  a[href^="tel:"] {
    color: $body-color !important;
  }

  .nav-top {
    padding: 10px 0 0;

    @include desktop {
      padding: 23px 0 18px;
    }

    li:not(:last-of-type) {
      margin-right: 25px;

      @include wide {
        margin-right: 50px;
      }

      @include hd {
        margin-right: 68px;
      }
    }

    a {
      @include wide {
        letter-spacing: 0.5px;
      }
    }
  }

  .nav-primary {
    position: relative;
    z-index: 0;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -1000px;
      bottom: -5px;
      left: 15px;
      z-index: -1;
    }

    &::after {
      content: '';
      position: absolute;
      left: -143px;
      width: 162px;
      height: 60px;
      z-index: -1;
      mask-size: unset;
      mask-image: url('../images/nav-decoration.webp');
    }

    > li:not(:last-of-type) {
      margin-right: 20px;
    }

    a {
      text-transform: uppercase;
      color: $white;
      font-weight: 600;
      padding: 23px 0 8px;
      position: relative;

      @include links-transition();

      @include wide {
        letter-spacing: 1px;
      }

      &::before {
        content: '';
        height: 1px;
        background-color: $white;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;

        @include links-transition();
      }
    }

    a:hover::before {
      width: 100%;
    }
  }
}

li.menu-item-has-children.open .sub-menu {
  visibility: visible;
  opacity: 1;
}

.sub-menu {
  visibility: hidden;
  opacity: 0;
  list-style: none;
  padding: 0;
  z-index: 1;

  @include links-transition((visibility, opacity, top));
}

.nav-top .sub-menu {
  margin-top: 19px;
}

.nav-primary .sub-menu {
  margin-top: 7px;
}

.banner .sub-menu {
  background-color: $white;
  position: absolute;
  max-width: 320px;
  min-width: 180px;

  a {
    color: $black;
    padding: 20px 15px 0;
  }

  li:last-of-type a {
    padding-bottom: 20px;
  }
}

.banner,
.sideNav {
  li.menu-item-has-children > a {
    @extend .down-caret;

    &::after {
      margin-left: 9px;
      position: relative;
    }
  }

  li.menu-item-has-children.open > a {
    @extend .up-caret;
  }
}
