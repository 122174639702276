.hero-panel,
.prefooter-panel {
  position: relative;

  .container {
    position: relative;
    z-index: 1;
  }
}

.hero-panel {
  background-color: $white;
}

body.home .hero-panel {
  @include laptop {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 600px;
  }

  @include wide {
    min-height: 700px;
  }

  @include hd {
    min-height: 806px;
  }

  > .container {
    @include laptop {
      flex-grow: 1;
      display: flex;
      align-items: center;
    }
  }
}

.hero,
.prefooter {
  background-size: cover;
  height: 250px;

  @include tablet {
    height: 400px;
  }

  @include laptop {
    position: absolute;
    height: 100%;
    width: 100%;
  }
}

.hero-content {
  padding: 30px 0;
  text-align: center;

  @include laptop {
    padding: 100px 0 90px;
    text-align: left;
    width: 750px;
  }

  @include desktop {
    padding: 130px 0 120px;
  }

  @include wide {
    padding: 163px 0 150px;
    width: 900px;
  }

  .line1,
  .line2,
  .line3 {
    color: $heading-color;
    text-transform: uppercase;

    @include laptop {
      color: $white;
      filter: drop-shadow(1px 1px 2px black);
    }
  }

  .line1,
  .line2 {
    font-size: 35px;
    font-weight: 800;
    line-height: 0.95;
    letter-spacing: 1.5px;
    display: inline-block;

    @include tablet {
      font-size: 40px;
    }

    @include laptop {
      display: block;
    }
  }

  .line1 {
    @include laptop {
      font-size: 45px;
    }

    @include wide {
      font-size: 55px;
    }
  }

  .line2 {
    @include laptop {
      font-size: 60px;
    }

    @include wide {
      font-size: 71px;
    }
  }

  .line3 {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 1px;

    @include laptop {
      font-size: 16px;
    }

    @include wide {
      font-size: 17px;
      letter-spacing: 1.7px;
    }
  }

  .btn {
    margin-top: 7px;

    &:not(:first-of-type) {
      @include tablet {
        margin-left: 20px;
      }
    }
  }
}

.overlay::after {
  @include laptop {
    content: '';
    background: rgba($black, 0.4);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.prefooter-panel.overlay::after {
  background: rgba($black, 0.25);
}

.strapline {
  position: relative;
  text-align: center;
  padding: 20px 0;
  z-index: 0;

  @include laptop {
    padding: 30px 0;
  }

  @include wide {
    padding: 43px 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;

    @include laptop {
      display: none;
    }
  }

  p {
    color: $white;
    font-weight: 500;
    line-height: 2.1;
    letter-spacing: 0.35px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}
