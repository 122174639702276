.sidebar-container {
  margin-top: 1.5rem;

  @include laptop {
    margin-top: 0;
  }

  @include desktop {
    max-width: 300px;
    margin-left: auto;
  }

  @include wide {
    max-width: 400px;
  }
}

.sidebar {
  position: relative;
  z-index: 0;

  &::before {
    @include laptop {
      content: '';
      background-color: $white;
      position: absolute;
      top: 0;
      right: -10000px;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  section:not(:last-of-type) {
    margin-bottom: 1.5rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
      padding-bottom: 6px;
      margin-bottom: 0;
    }
  }

  .children {
    margin-top: 10px;
    margin-left: 15px;
    padding-left: 10px;
    display: none;
    border-left: 2px solid $body-color;
  }

  li.current_page_item,
  li.current_page_parent,
  li.current_page_ancestor {
    > ul.children {
      display: block;
    }

    > a {
      color: $body-color;
    }
  }
}
