a[href^="tel:"] {
  color: $body-color;
}

a[target="_blank"] {
  @include fa-pseudo($icon: '\f08e', $position: 'after', $weight: 400, $size: inherit, $color: inherit);
}

a[href$=".pdf"] {
  @include fa-pseudo($icon: '\f1c1', $position: 'after', $weight: 700, $size: inherit, $color: inherit);
}

a[target="_blank"],
a[href$=".pdf"] {
  &::after {
    margin-left: 7px;
  }
}

a[href^="mailto:"],
a.no-external-link {
  &::after {
    content: '' !important;
    margin-left: 0;
  }
}

.google-map {
  position: relative;
  overflow: hidden;
  flex-grow: 1;

  iframe {
    width: 100%;
    height: 100%;
    min-height: 400px;
    border: 0;
    position: relative;

    @include laptop {
      min-height: 300px;
    }
  }
}

.search-form {
  display: flex;
  flex-wrap: wrap;

  label {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

a.page-numbers,
span.page-numbers {
  margin-right: 5px;
}

.page-header .h1 {
  text-align: center;
  margin-bottom: 25px;

  @include tablet {
    margin-bottom: 35px;
  }
}

.page-content-panel {
  // a:not(.btn):not(.gallery-image):hover {
  //   color: #808080;
  // }

  li {
    margin-bottom: 0.5rem;
  }

  .content-page > h2:not(:first-child) {
    margin-top: 35px;
  }
}
