.icon {
  position: relative;
  background-size: cover;
  background-size: contain;
  background-repeat: no-repeat;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 15px;
  transform: scale(0.9);

  @include laptop {
    margin: 0 20px 0 0;
    transform: scale(1);
  }

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.underline {
  border-bottom: 2px solid;
  padding-bottom: 19px;
  margin-bottom: 19px;
}

h3.underline {
  font-weight: 600;
}

.h1 span.sub-header {
  font-size: 27.5px;
  display: block;
  margin-bottom: 5px;
}

.why-choose {
  .h1 {
    margin-bottom: 35px;

    @include wide {
      margin-bottom: 10px;
    }
  }

  .feature {
    margin: 0 auto;

    @include tablet {
      max-width: 462px;
    }
  }

  .feature-col {
    margin-bottom: 20px;
  }

  .feature-col:nth-of-type(even) .feature {
    @include tablet {
      margin-right: 0;
      margin-left: auto;
    }
  }

  .image-col {
    padding-right: 37px;
  }

  .image-container {
    position: relative;
    max-width: 467px;
    border-top: 2px solid;

    @include wide {
      padding-top: 14px;
      margin-top: 12px;
    }

    .btn-container {
      background-color: $body-bkg;
      padding: 9px 0 0 10px;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;

      .btn {
        letter-spacing: 0.3px;
      }
    }
  }
}

.service-panels {
  @include laptop {
    margin-bottom: 112px;
  }

  @include wide {
    margin-bottom: 145px;
  }

  .service {
    position: relative;
    padding-top: 30px;

    @include tablet {
      padding-top: 40px;
    }

    @include laptop {
      padding-top: 0;
      margin-top: 107px;
    }

    @include wide {
      margin-top: 139px;
    }
  }

  .service-image {
    background-size: cover;
    min-height: 250px;

    @include tablet {
      min-height: 400px;
    }

    @include laptop {
      position: absolute;
      top: -60px;
      bottom: -50px;
      width: 47.7%;
    }

    @include wide {
      top: -92px;
      bottom: -70px;
      width: 48.7%;
    }
  }

  .service-content {
    padding: 30px 0;
    text-align: center;

    @include tablet {
      padding: 40px 0;
    }

    @include laptop {
      padding: 55px 0;
      text-align: left;
      width: 46%;
    }

    @include wide {
      padding: 84px 0;
      width: 580px;
    }

    .underline {
      padding-bottom: 7px;
      margin-bottom: 11px;
    }

    p {
      line-height: 1.4;
    }

    .btn {
      @include laptop {
        letter-spacing: 0.4px;
      }
    }

    .btn span {
      display: none;

      @include desktop {
        display: inline;
      }
    }
  }

  .service:first-of-type {
    @include laptop {
      margin-top: 67px;
    }
  }

  .service:nth-of-type(odd) {
    .service-image {
      @include laptop {
        left: 0;
      }
    }

    .service-content {
      @include laptop {
        margin-left: auto;
      }

      @include wide {
        margin-right: 40px;
      }

      * {
        color: $white;
      }

      .underline {
        border-color: $white;
      }
    }
  }

  .service:nth-of-type(even) {
    .service-image {
      @include laptop {
        right: 0;
      }
    }

    .service-content {
      @include wide {
        padding: 114px 0 56px;
      }
    }
  }
}

.gallery-panel {
  &.panel {
    @include hd {
      padding-bottom: 70px;
    }
  }

  .lightgallery.block {
    @include wide {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  hr {
    border-top: 2px solid $white;
    max-width: 575px;
    margin: 20px auto 32px;
  }
}
