.btn {
  border: 1px solid $white !important;
  border-radius: 0;
  box-shadow: 0 0 0 !important;
  text-transform: uppercase;
  padding: 10px 30px 9px;
  width: 100%;

  @include tablet {
    width: auto;
  }

  @include laptop {
    font-size: 17px;
  }

  @include wide {
    font-size: 18px;
  }
}

.btn-primary {
  @include links-transition();
}

.btn-primary:hover,
.gform_wrapper .gform_footer input[type=submit]:hover {
  filter: brightness(1.3);
}

.search-submit {
  width: auto;
  border: 0 !important;

  @extend .btn;
  @extend .btn-primary;
}
