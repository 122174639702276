a.scrollup {
  position: fixed;
  z-index: 1000;
  bottom: 20px;
  right: 20px;
  display: none;
  border: 1px solid $white;
  padding: 0;
  height: 40px;
  width: 40px;
  opacity: 0.7;

  @include links-transition();

  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
    opacity: 1;
  }

  &::before {
    border-style: solid;
    border-width: 3px 3px 0 0;
    content: '';
    display: inline-block;
    height: 12px;
    width: 12px;
    top: 8px;
    left: 13px;
    position: relative;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    vertical-align: middle;
    border-color: #fff;
  }
}
